import "./Login.css";
import Frame from "./Frame";
import GeneralButton from "heroic-ui-library/dist/components/Button";

import { get_cookie_value, top } from "../../../lib";
import { useState } from "react";
import Input from "heroic-ui-library/dist/components/Input";
import axios from "axios";
import { API_URL } from "../../../services/api";
import { toast } from "react-toastify";
import Loader from "../Leaderboard/Loader";

export default function AskChildCodeFromStudent() {
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getToken = () => {
    return get_cookie_value("access_token2");
  };

  const handleCodeEnter = async () => {
    if (code === "") {
      return toast.error("Please enter class code.");
    }

    if (!getToken()) {
      return toast.error("Please login first.");
    }

    setIsLoading(true);

    const response = await axios.post(API_URL, {
      class_code: code,
      cmd: "gms_INPortalAdmin.useClassCode",
      token: getToken(),
    });

    if (response.data.state == 0) {
      top.sw.loginSucceed();
    } else {
      toast.error(response.data.error);
    }

    setIsLoading(false);
  };

  const handleForSkip = () => {
    top.sw.loginSucceed();
  };

  return (
    <div className="main-bg relative space-x-8">
      <img
        className="absolute z-50 left-5 top-5 w-56 cursor-pointer"
        src="/images/logo.png"
        alt="logo"
        onClick={() => (window.location.href = "https://heroicgameday.com")}
      />
      <img
        className="absolute top-0"
        src="/images/Ill-Hero_Center_Poster_03.webp"
        alt="characters"
      />

      <Frame className="!max-w-[40rem]">
        <div className="flex flex-col justify-center items-center">
          <img className="w-48 mb-10" src="/images/logo.png" alt="characters" />
          <h1 className="top-heading text-center mb-3">Class Code</h1>
          <h3 className="text-3xl text-center mb-5">
            Enter the class code or skip it for now
          </h3>
          {isLoading && <Loader color="red" />}

          {!isLoading && (
            <>
              <Input
                value={code}
                onChange={(e) => setCode(e.currentTarget.value)}
              />
              <div className="mt-10 space-x-6 flex items-center justify-center">
                <GeneralButton
                  className=" !text-4xl"
                  label="Skip for now"
                  btnType="red"
                  onClick={handleForSkip}
                />
                <GeneralButton
                  className=" !text-4xl"
                  label="Submit"
                  btnType="green"
                  onClick={handleCodeEnter}
                />
              </div>
            </>
          )}
        </div>
      </Frame>
    </div>
  );
}
