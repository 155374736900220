import "./Login.css";
import Frame from "./Frame";
import GeneralButton from "heroic-ui-library/dist/components/Button";

import { get_cookie_value, top } from "../../../lib";

export default function TeacherLogin() {
  const getToken = () => {
    return get_cookie_value("access_token2");
  };

  return (
    <div className="main-bg relative space-x-8">
      <img
        className="absolute z-50 left-5 top-5 w-56 cursor-pointer"
        src="/images/logo.png"
        alt="logo"
        onClick={() => (window.location.href = "https://heroicgameday.com")}
      />
      <img
        className="absolute top-0"
        src="/images/Ill-Hero_Center_Poster_03.webp"
        alt="characters"
      />

      <Frame>
        <div className="flex flex-col justify-center items-center">
          <h1 className="top-heading text-center mb-3">Play the Game</h1>
          <img className="w-48 mb-10" src="/images/logo.png" alt="characters" />
          <GeneralButton
            className="!text-4xl reduce-outline"
            label="Play Game"
            btnType="green"
            onClick={() => top.sw.loginSucceed()}
          />
        </div>
      </Frame>
      <Frame>
        <div className="flex flex-col justify-center items-center">
          <h1 className="top-heading text-center mb-3">Teacher Dashboard</h1>
          <img
            className="w-48 mb-14"
            src="/images/teacher_icon.png"
            alt="characters"
          />

          <GeneralButton
            className="!text-4xl reduce-outline"
            label="Teacher Dashboard"
            btnType="green"
            onClick={() => {
              window.location.href =
                "https://mainbackend.woogiu.com/client/gmsui/teacher-dashboard/build/?token=" +
                getToken() +
                "&user_loc=us";
            }}
          />
        </div>
      </Frame>
    </div>
  );
}
