export default function Loader({ color }: { color?: string }) {
  return (
    <div className={"lds-roller " + (color ? color : "")}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
