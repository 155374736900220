import "./Login.css";
import Frame from "./Frame";
import GeneralButton from "heroic-ui-library/dist/components/Button";

import { top } from "../../../lib";
import { useEffect, useState } from "react";
import Loader from "../Leaderboard/Loader";
import QRCode from "react-qr-code";

export default function QRCodeShow() {
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const getLink = sessionStorage.getItem("gms_full_link");
    setCode(getLink ? getLink : "Link not found");
    setIsLoading(false);
  }, []);

  return (
    <div className="main-bg relative space-x-8">
      <img
        className="absolute z-50 left-5 top-5 w-56 cursor-pointer"
        src="/images/logo.png"
        alt="logo"
        onClick={() => (window.location.href = "https://heroicgameday.com")}
      />
      <img
        className="absolute top-0"
        src="/images/Ill-Hero_Center_Poster_03.webp"
        alt="characters"
      />

      <Frame className="!max-w-[40rem]">
        <div className="flex flex-col justify-center items-center">
          <h1 className="top-heading text-center mb-12">
            Scan the QR Code to Play on{" "}
            <span className="!text-red">Mobile</span>
          </h1>
          <QRCode value={code} />
          <h3 className="text-3xl text-center mt-12">
            or skip to continue play.
          </h3>
          {isLoading && <Loader color="red" />}

          {!isLoading && (
            <>
              <div className="mt-10 space-x-6 flex items-center justify-center">
                <GeneralButton
                  className=" !text-4xl"
                  label="Skip"
                  btnType="red"
                  onClick={() => top.sw.loginSucceed("skip")}
                />
              </div>
            </>
          )}
        </div>
      </Frame>
    </div>
  );
}
