import "./Login.css";
import Footer from "./Footer";
import Frame from "./Frame";
import Header from "./Header";
import LoginForm from "./Form";
import GeneralButton from "heroic-ui-library/dist/components/Button";
import { useCallback, useEffect, useState } from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { apiCallerPost, sendWoogiEvent } from "../../../services/api";
import Spinner from "../../Shared/Spinner/Spinner";
import { useAuthStore } from "../../../store/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useUIDataStore } from "../../../store/ui";
import { useWhatsNewsStore } from "../../../store/whatsnew";
import { useBlogStore } from "../../../store/blog";
import { useComicsStore } from "../../../store/comics";
import { useEventStore } from "../../../store/events";
import { useVideosStore } from "../../../store/videos";
import { useGamesStore } from "../../../store/games";
import { useNewsStore } from "../../../store/news";
import { useStoryStore } from "../../../store/story";
import { useSeasonStore } from "../../../store/season";
import { useAnouncementsStore } from "../../../store/Anoucements";
import { anotherLoginRound, isDev } from "../../../lib";

type FormsType = "login" | "forgot-password";

export default function Login() {
  const [form, setform] = useState<FormsType>("login");
  const [isSSLoading, setIsSSLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [error, setError] = useState("");

  const userAuth = useAuthStore((store) => store.userAuth);
  const accessToken = userAuth?.access_token;

  const setLoader = useUIDataStore((store) => store.setLoader);
  const whatsnewLoader = useWhatsNewsStore((store) => store.loadNews);

  const login = useAuthStore((store) => store.login);

  const loadBlog = useBlogStore((store) => store.loadBlog);
  const loadComics = useComicsStore((store) => store.loadComics);
  const loadEvents = useEventStore((store) => store.loadEvents);
  const loadVideos = useVideosStore((store) => store.loadVideos);
  const loadGames = useGamesStore((store) => store.loadGames);
  const loadNews = useNewsStore((store) => store.loadNews);
  const loadStory = useStoryStore((store) => store.loadStory);
  const loadSeason = useSeasonStore((store) => store.loadSeason);
  const loadUiData = useUIDataStore((store) => store.loadUiData);
  const loadAnnouncementStore = useAnouncementsStore(
    (store) => store.loadAnnouncements
  );
  const updateUser = useAuthStore((store) => store.updateUser);

  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    async (user: any) => {
      if (!user.access_token) return;

      const [
        news,
        season,
        events,
        videos,
        games,
        comics,
        blog,
        stories,
        whatsNew,
        showNameAndPwd,
        skillRadar,
        nickname,
        ancments,
      ] = await Promise.all([
        apiCallerPost({
          cmd: "auth_HeroLeaguePortalIndex.getNews",
          limit: 20,
          token: user.access_token,
        }),
        apiCallerPost({
          cmd: "auth_HeroLeaguePortalIndex.getCurSeason",
          token: user.access_token,
        }),
        apiCallerPost({
          cmd: "auth_HeroLeaguePortalIndex.getEvents",
          token: user.access_token,
        }),
        apiCallerPost({
          cmd: "auth_HeroLeaguePortalIndex.getVideos",
          token: user.access_token,
        }),
        apiCallerPost({
          cmd: "auth_HeroLeaguePortalIndex.getMiniGames",
          token: user.access_token,
        }),
        apiCallerPost({
          cmd: "auth_HeroLeaguePortalIndex.getComics",
          token: user.access_token,
        }),
        apiCallerPost({
          cmd: "auth_HeroLeagueBlogOpen.getBlog",
          token: user.access_token,
        }),
        apiCallerPost({
          cmd: "auth_HeroLeaguePortalIndex.getCurSeasonStory",
          token: user.access_token,
        }),
        apiCallerPost({
          cmd: "awc_portal_WhatsNews.getNews",
          token: user.access_token,
        }),
        apiCallerPost({
          cmd: "auth_HeroLeaguePortalIndex.showNameAndPwd",
          user_id: user.id,
          token: user.access_token,
        }),
        apiCallerPost({
          cmd: "auth_HeroLeaguePortalIndex.showSkillRadar",
          token: user.access_token,
        }),
        apiCallerPost({
          cmd: "auth_HeroLeaguePortalIndex.getNickname",
          token: user.access_token,
        }),
        apiCallerPost({
          cmd: "auth_HeroLeaguePortalIndex.getAnnouncement",
          token: user.access_token,
        }),
      ]);

      whatsnewLoader(whatsNew.data.data);
      loadSeason(season.data.data);
      loadStory(stories.data.data);
      loadNews(news.data.data);
      loadEvents(events.data.data);
      loadVideos(videos.data.data);
      loadGames(games.data.data);
      loadComics(comics.data.data);

      loadBlog(blog.data.blogs);
      loadAnnouncementStore(ancments.data);

      loadUiData({
        passwordModal: showNameAndPwd.data,
        skillRadar: skillRadar.data,
      });
      updateUser({ ...user.user, nickname: nickname.data });
    },
    [
      loadAnnouncementStore,
      loadBlog,
      loadComics,
      loadEvents,
      loadGames,
      loadNews,
      loadSeason,
      loadStory,
      loadUiData,
      loadVideos,
      updateUser,
      whatsnewLoader,
    ]
  );

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        if (
          document.referrer &&
          document.referrer.indexOf("portal.americau.com") === -1
        ) {
          console.log("referer is not from portal, goto login page");

          if (isDev) {
            const user = await anotherLoginRound();
            if (user) {
              await fetchData(user);
              login(user);
              setLoader(true);
              navigate("/index");
            }
          }
        } else if (
          state?.from.pathname === "/index" &&
          state?.from.search === "?portal=1"
        ) {
          console.log("goto portal now");
          const user = await anotherLoginRound();
          if (user) {
            await fetchData(user);
            login(user);
            setLoader(true);
          }
          navigate("/index");
        }
      } catch (error) {}

      setLoading(false);
      sendWoogiEvent("PAGE_LOGIN");
    })();
  }, [fetchData, setLoader, login, accessToken, navigate]);

  if (loading)
    return (
      <div className="w-screen h-screen top-0 left-0 absolute  bg-white flex justify-center items-center">
        <Spinner />
      </div>
    );

  const handleSSOLink = () => {
    setIsSSLoading(true);

    apiCallerPost({
      cmd: "gms_GoogleSSO.getLoginLink",
    }).then((resp) => {
      setIsSSLoading(false);

      window.location.href = resp.data;
    });
  };

  return (
    <div className="main-bg relative">
      <img
        className="absolute top-0"
        src="/images/Ill-Hero_Center_Poster_03.webp"
        alt="characters"
      />

      <Header />
      <Frame>
        {form === "login" && (
          <>
            <h1 className="top-heading text-center mb-3">SIGN IN</h1>
            <div className="flex justify-center items-center">
              <div className="border-t-2 border-dotted border-gray-400 flex-1" />
              <div className="divider-text text-[2.5em]">Log in with</div>
              <div className="border-t-2 border-dotted border-gray-400 flex-1" />
            </div>
            <div className="grid grid-cols-3 items-center justify-center gap-x-5 mt-3">
              <GeneralButton
                className="submit-btn extra-btn"
                label="Clever"
                btnType="green"
                onClick={() =>
                  window.open(
                    "https://portal.americau.com/woogi/0.1/static/oauth/indexOfClever.php",
                    "_self"
                  )
                }
              />
              <GeneralButton
                className=" submit-btn extra-btn"
                label="ClassLink"
                btnType="green"
                onClick={() => {
                  window.open(
                    "https://api.americau.com/login/classlink",
                    "_self"
                  );
                }}
              />
              <div className=" flex flex-row items-center justify-center">
                {!isSSLoading ? (
                  <GeneralButton
                    className="submit-btn extra-btn"
                    label="Google"
                    btnType="green"
                    onClick={handleSSOLink}
                  />
                ) : (
                  <div className="loader" />
                )}
              </div>
            </div>

            <div className="flex justify-center items-center mt-3 mb-3">
              <div className="border-t-2 border-dotted border-gray-400 flex-1" />
              <div className="divider-text text-[2em]">
                or, Log in with username
              </div>
              <div className="border-t-2 border-dotted border-gray-400 flex-1" />
            </div>
            <LoginForm
              fetchData={fetchData}
              setError={setError}
              error={error}
            />
            <div className=" mt-4 text-center">
              <h2
                onClick={() => setform("forgot-password")}
                className="third-heading cursor-pointer text-center link "
              >
                Forgot Password?
              </h2>
            </div>
          </>
        )}

        {form === "forgot-password" && (
          <ForgotPasswordForm onLogin={() => setform("login")} />
        )}

        <div className="mt-2  text-center">
          <span className="third-heading text-black">
            DON'T HAVE AN ACCOUNT?
          </span>
          <span
            className="third-heading ml-2 link"
            onClick={() =>
              window.open("https://signup.americau.com/", "_blank")
            }
          >
            Sign Up
          </span>

          <div className="flex flex-row justify-between items-end mt-3">
            <span
              className="third-heading link  "
              onClick={() =>
                window.open("https://heroicgameday.com/", "_blank")
              }
            >
              Learn More
            </span>
          </div>
        </div>
      </Frame>
      <Footer />
    </div>
  );
}
