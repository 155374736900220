import "./App.css";
import PublicMain from "./Components/Main/PublicMain";
import { Navigate, Route, Routes } from "react-router-dom";

import MeetHeroes from "./Components/Main/MeetHeroes/MeetHeroes";
import BlogListing from "./Components/Main/BlogListing/BlogListing";
import Leaderboard from "./Components/Main/Leaderboard/Leaderboard";
import SeasonPassStories from "./Components/Main/SeasonPass/SeasonPassStories/SeasonPassStories";
import ComicsListing from "./Components/Main/ComicsListing/ComicsListing";
import VideosPage from "./Components/Main/VideosPage/VideosPage";
import BlogDetail from "./Components/Main/BlogListing/BlogDetail/BlogDetail";
import News from "./Components/Main/News/News";
import Games from "./Components/Main/Games/Games";
import SeasonPassMain from "./Components/Main/SeasonPass/SeasonPass";
import Home from "./Components/Main/Home/Home";
import Events from "./Components/Main/Events/Events";
import Comics from "./Components/Main/Comics/Comics";

import ProtectedRoute from "./Components/Shared/ProtectedRoute/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modals from "./Components/Shared/Modals";
import Login from "./Components/Main/Login/Login";
import TeacherLogin from "./Components/Main/Login/TeacherLogin";
import AskChildCodeFromStudent from "./Components/Main/Login/AskChildCodeFromStudent";
import QRCodeShow from "./Components/Main/Login/QRCodeShow";

function App() {
  console.log("Portal version", 1.73);

  return (
    <>
      <Modals>
        <Routes>
          <Route path="/login" element={<Login />} />
          {
            // Following three routes are being handled by CaiYaun
          }
          <Route path="/teacher-login" element={<TeacherLogin />} />
          <Route
            path="/class-selection"
            element={<AskChildCodeFromStudent />}
          />
          <Route path="/qr-code-option" element={<QRCodeShow />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <PublicMain />
              </ProtectedRoute>
            }
          >
            <Route path="/index" element={<Home />} />
            <Route path="meet-the-heroes" element={<MeetHeroes />} />
            <Route path="blog/blog-detail" element={<BlogDetail />} />
            <Route path="blog" element={<BlogListing />} />
            <Route path="videos" element={<VideosPage />} />

            <Route path="comics" element={<Comics />} />
            <Route path="comics-list" element={<ComicsListing />} />
            <Route path="news" element={<News />} />
            <Route path="games" element={<Games />} />
            <Route
              path="seasonpass/seasonpass-list"
              element={<SeasonPassStories />}
            />
            <Route path="seasonpass" element={<SeasonPassMain />} />
            <Route path="events" element={<Events />} />
            <Route path="leaderboard" element={<Leaderboard />} />
          </Route>
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Modals>
      <ToastContainer />
    </>
  );
}

export default App;
